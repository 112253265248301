<template>
  <div class="site-detail">
    <breadcrumb :breadcrumbList="breadcrumbSiteDetail"></breadcrumb>
    <div class="site-detail-content">
      <siteDetail :ruleInfo='currentRuleForm'></siteDetail>
    </div>
    <div class="mask"></div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { COMPANY_ID } from '@/common/constant'
import { timeCategory, payOptions, breadcrumbSiteDetail } from '../../config'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import siteDetail from '@/views/main/reserve/site/base-ui/rule-form/rule-form.vue'
import cache from '@/utils/cache'

export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    siteDetail
  },
  setup() {

    const currentRuleForm = ref(cache.getCache('ruleInfo'))

    return {
      breadcrumbSiteDetail,
      currentRuleForm
    }

  }
})
</script>

<style scoped lang="less">
.site-detail {
  position: relative;
  // .mask{
  //   position: absolute;
  //   height: 100vh;
  //   width: 100%;
  //   top: 0;
  // }
  .site-detail-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




