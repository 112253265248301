const addBreadcrumbList = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '预约设置',
    path: ''
  }
]

const checkBreadcrumbList = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '查看预付设置',
    path: ''
  }
]

const addcheckBreadcrumb = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '预约设置',
    path: '/main/reserve/site'
  },
  {
    name: '新建预约规则',
    path: ''
  }
]
const editBreadcrumbList = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '预约设置',
    path: '/main/reserve/site'
  },
  {
    name: '修改预约规则',
    path: ''
  }
]

const timeCategory = [
  {
    label: '分钟',
    value: '1'
  },
  {
    label: '小时',
    value: '2'
  },
  {
    label: '天',
    value: '3'
  }
]

const payOptions = [
  {
    label: '百分比',
    value: '1'
  },
  {
    label: '固定金额',
    value: '2'
  }
]

const breadcrumbSiteDetail = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '预约设置',
    path: '/main/reserve/site'
  },
  {
    name: '预约规则详情',
    path: ''
  }
]

const reserveRule = {
  ruleName: [{ required: true, message: '请填写规则名称', trigger: 'blur' }],
  shopId: [{ required: true, message: '请选择门店', trigger: 'blur' }],
  startMinutes: [{ required: true, message: '请填写预约范围', trigger: 'blur' }],
  endMinutes: [{ required: true, message: '请填写预约范围', trigger: 'blur' }],
}

export {
  checkBreadcrumbList,
  addBreadcrumbList,
  editBreadcrumbList,
  timeCategory,
  payOptions,
  addcheckBreadcrumb,
  breadcrumbSiteDetail,
  reserveRule
}
