<template>
  <div class="breadcrumb">
    <div class="content">
      <el-breadcrumb separator=">">
        <template v-for="item in breadcrumbList" :key="item">
          <el-breadcrumb-item v-if="item.path" :to="{ path: item.path,query:item.query }">{{ item.name }}</el-breadcrumb-item>
          <el-breadcrumb-item v-else>{{ item.name }}</el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    breadcrumbList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {

  },
  setup() {
    return {
    }

  }
})
</script>

<style scoped lang="less">
.breadcrumb {
  box-shadow: 0px 1px 2px 0px rgba(184, 184, 184, 0.5);
  color: rgba(80, 80, 80, 1);
  height: 40px;
  z-index: 1000;

  .content {
    position: fixed;
    width: 100%;
    left: 122px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    z-index: 100;
    border-top: 2px solid #f0f0f0;
    background-color: rgba(255, 255, 255, 1);
    height: 40px;
    // top: 80px;
  }
}
</style>
