<template>
  <div class="add-site">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" class="ruleForm" :size="formSize">
      <el-form-item label="规格名称：" prop="name">
        <el-input readonly v-model="ruleForm.ruleName" />
      </el-form-item>

      <el-form-item label="选择门店：" prop="name">
        <div class="shop">
          <el-select readonly @change="handleShopChange" v-model="shopId" class="m-2" placeholder="选择门店"
            size="large">
            <el-option readonly v-for="item in shopList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <!-- <el-form-item label="预约时间：" prop="name">
        <div class="time">
          <el-input readonly placeholder="" v-model="ruleForm.appWeekDays" />
          <el-time-select readonly v-model="ruleForm.appStartTime" :max-time="ruleForm.appEndTime" class="mr-4"
            placeholder="开始时间" start="08:00" step="00:15" end="22:30"></el-time-select>
          <span>到</span>
          <el-time-select readonly v-model="ruleForm.appEndTime" :min-time="ruleForm.appStartTime" placeholder="结束时间"
            start="08:30" step="00:15" end="22:30"></el-time-select>
        </div>
      </el-form-item> -->

      <el-form-item label="预约范围：" prop="name">
        <div class="scope">
          <el-input readonly v-model="ruleForm.startMinutes" />
          分钟
          <span>到</span>
          <el-input readonly v-model="ruleForm.endMinutes" />
          <span>天内的到店服务</span>
        </div>
      </el-form-item>

      <el-form-item label="项目占用时长：" prop="name">
        <div class="duration">
          <el-radio readonly v-model="ruleForm.serveOccupyTimeRadio" label="0" size="large">按照项目时长计算占用</el-radio>
          <el-radio readonly v-model="ruleForm.serveOccupyTimeRadio" label="1" size="large">
            <span>固定为</span>
            <el-input readonly :disabled="ruleForm.serveOccupyTimeRadio == '0'" v-model="ruleForm.serveOccupyFlag" />
            <span>分钟</span>
          </el-radio>
        </div>
      </el-form-item>

      <el-form-item label="取消预约：" prop="name">
        <div class="cancel-reserve">
          <el-radio readonly v-model="ruleForm.cancelTimeLimitRadio" label="0" size="large">任意时间</el-radio>
          <el-radio readonly v-model="ruleForm.cancelTimeLimitRadio" label="1" size="large">
            <span>预约到店时间前</span>
            <el-input readonly :disabled="ruleForm.cancelTimeLimitRadio == '0'" v-model="ruleForm.cancelTimeLimit" />
            <span>分钟，客户需联系客服取消或修改预约单</span>
          </el-radio>

        </div>
      </el-form-item>

      <el-form-item label="预约超时取消：" prop="name">
        <div class="time-out">
          <el-radio readonly v-model="ruleForm.autoCancelRadio" label="0" size="large">否</el-radio>
          <el-radio readonly v-model="ruleForm.autoCancelRadio" label="1" size="large">
            <span>超过客户预约到店时间</span>
            <el-input readonly :disabled="ruleForm.autoCancelRadio == '0'" v-model="ruleForm.autoCancel" />
            <span>分钟未到店，自动取消预约</span>
          </el-radio>
        </div>
      </el-form-item>
      <el-form-item label="线上预约:" prop="servePayFlag">
        <div class="online-reserve">
          <el-radio v-model="ruleForm.servePayFlag" label="0" size="large">无需支付</el-radio>
          <el-radio v-model="ruleForm.servePayFlag" label="2" size="large">
            <span>需支付</span>
            <el-select v-model="ruleForm.servePayKind" class="m-2" placeholder="按百分比" size="large">
              <el-option v-for="item in payOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="ruleForm.reservePayAmount">
              <template #suffix>
                <span v-if="ruleForm.servePayKind == '2'">元</span>
                <span v-else>%</span>
              </template>
            </el-input>
          </el-radio>
        </div>
      </el-form-item>

      <!-- <el-form-item>
        <el-button @click="handleSaveClick" class="save-btn" type="primary">保存</el-button>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import emitter from '@/utils/eventbus'
import { COMPANY_ID } from '@/common/constant'
import { timeCategory } from '../../config'
import { payOptions } from '../../config'
import { getRuleBindShop } from '@/service/main/reserve'

export default defineComponent({
  props: {
    ruleInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  setup(props, { emit }) {
    const store = useStore()

    const ruleForm = reactive({
      ruleName: '',
      companyId: COMPANY_ID,
      shopId: '',
      appWeekDays: "",
      appStartTime: "",
      appEndTime: "",
      startMinutes: "",
      endMinutes: "",
      serveOccupyFlag: "",
      timeCategory: '',
      serveOccupyTimeRadio: '',
      cancelTimeLimitRadio: '',
      cancelTimeLimit: '',
      autoCancel: '',
      autoCancelRadio: '',
      id: ''
    })

    const emptyForm = () => {
      for (let key in ruleForm) {
        if (key != 'companyId') {
          ruleForm[key] = ''
        }
      }
    }

    watch(() => props.ruleInfo, (n) => {
      if (n != null) {
        // console.log(n); // TODO:
        ruleForm.id = n.id
        ruleForm.ruleName = n.ruleName
        ruleForm.shopId = n.shopId
        ruleForm.appWeekDays = n.appWeekDays
        ruleForm.appStartTime = n.appStartTime
        ruleForm.appEndTime = n.appEndTime
        ruleForm.endMinutes = (n.endMinutes) / 1440
        ruleForm.startMinutes = n.startMinutes

        if (n.serveOccupyFlag == 0) {
          ruleForm.serveOccupyFlag = 0
          ruleForm.serveOccupyTimeRadio = '0'
        } else {
          ruleForm.serveOccupyTimeRadio = '1'
          ruleForm.serveOccupyFlag = n.serveOccupyFlag
        }

        if (n.cancelTimeLimit == 0) {
          ruleForm.cancelTimeLimit = 0
          ruleForm.cancelTimeLimitRadio = '0'
        } else {
          ruleForm.cancelTimeLimitRadio = '1'
          ruleForm.cancelTimeLimit = n.cancelTimeLimit
        }

        if (n.autoCancel == 0) {
          ruleForm.autoCancel = 0
          ruleForm.autoCancelRadio = '0'
        } else {
          ruleForm.autoCancelRadio = '1'
          ruleForm.autoCancel = n.autoCancel
        }

      }
    },{
      deep:true,
      immediate:true
    })

    const shopList = ref([])
    const shopId = ref("")

    const handleSaveClick = () => {
      emit('updateRule', { ...ruleForm })
      store.dispatch('reserve/updateReserveRuleAction', { ...ruleForm })
    }


    const initPage = () => {
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
      getRuleBindShop(ruleForm.id).then(res=>{
        shopList.value = res.data
        shopId.value = shopList.value[0].id
      })
    }
    initPage()

    return {
      ruleForm,
      shopList,
      timeCategory,
      handleSaveClick,
      payOptions,
      shopId
    }

  }
})
</script>

<style scoped lang="less">
.ruleForm {
  .online-reserve {
    &:deep(.el-input) {
      max-width: 120px !important;
      margin-left: 10px;
    }
  }


  &:deep(.el-form-item) {
    .el-input {
      max-width: 600px;
    }
  }

  .shop {
    max-width: 600px;

    &:deep(.el-select) {
      width: 100%;
    }
  }

  .save-btn {
    width: 150px;
    height: 48px;
    margin-top: 10px;
  }

  .pause-reserve {
    .top {
      &:deep(.el-select) {
        max-width: 120px;
        margin: 0 10px;
      }

      &>span:last-child {
        color: rgba(42, 130, 228, 1);
        font-size: 14px;
      }
    }

    .center {
      color: rgba(166, 166, 166, 1);
      font-size: 12px;
    }
  }

  .time-out {
    &:deep(.el-input) {
      max-width: 80px;
      min-width: 60px;
      margin: 0 10px;
    }
  }

  .duration,
  .cancel-reserve {
    &:deep(.el-select) {
      max-width: 120px;
      margin: 0 10px;
    }
  }

  .duration {
    max-width: 600px;

    &:deep(.el-input) {
      max-width: 150px;
      margin: 0 10px;
    }
  }

  .cancel-reserve {
    display: flex;
    justify-content: space-between;
    max-width: 600px;

    &:deep(.el-input) {
      max-width: 100px;
      margin: 0 10px;
    }
  }

  .tips {

    .top,
    .bottom {
      display: flex;

      &:deep(.el-input) {
        max-width: 80px;
        min-width: 60px;
        margin: 0 10px;
      }
    }

    .bottom {
      margin-top: 10px;
    }
  }

  .time {
    display: flex;
    justify-content: space-between;
    max-width: 600px;

    span {
      margin: 0 10px;
    }

    &:deep(.el-input) {
      margin-right: 10px;
      max-width: 240px;
    }

    &:deep(.el-select) {
      max-width: 120px;
      margin-left: 10px;
    }
  }

  .scope {
    display: flex;
    max-width: 600px;
    justify-content: space-between;

    &:deep(.el-input) {
      max-width: 80px;
      min-width: 60px;
      margin-right: 10px;
    }

    &:deep(.el-select) {
      .el-input {
        max-width: 120px;
      }
    }

    span {
      margin-right: 10px;
    }
  }
}
</style>







